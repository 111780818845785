import urlBuilder from "@sanity/image-url";

type SanityProjectDetails = {
  projectId: string;
  dataset: string;
};

const sanityImageUrlBuilder = (sanityProjectDetails: SanityProjectDetails) => {
  return urlBuilder(sanityProjectDetails);
};

export default sanityImageUrlBuilder;
